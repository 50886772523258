.Footer {
    position: relative;
    padding: 20px 10px 30px;
    border-top: solid 1px #cbcbcb;
    transition: all 500ms ease-in-out;
}

.FooterSignUpForm {
    position: relative;
}

.FooterSignUpLabel {
    display: block;
    padding-bottom: 10px;
    text-transform: uppercase;
}

.FooterSignUpInputContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: solid 1px black;
}

.FooterSignUpInput {
    display: block;
    padding: 8px 12px 5px;
    box-sizing: border-box;
    border: none;
    width: 100%;
    flex: 1;
}

.FooterSignUpSubmit {
    background: transparent;
    border: none;
    padding: 2px 7px 0 0;
}

.FooterSignUpSubmitIcon {
    width: 15px;
    height: 15px;
}

.FooterSignUpSubmitIcon g {
    color: black;
    stroke-width: 3;
}

.FooterSignUpInput::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #cbcbcb;
    opacity: 1; /* Firefox */
}

.FooterSignUpInput:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #cbcbcb;
}

.FooterSignUpInput::-ms-input-placeholder { /* Microsoft Edge */
    color: #cbcbcb;
}

.FooterTextContainer {
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
}

.FooterTextEmail,
.FooterTextInstagram {
    display: none;
    padding-right: 5vw;
}

.FooterTextInstagram a {
    color: black;
    text-decoration: none;
}

@media only screen and (min-width: 768px) {
    .Footer {
        background: rgb(220,226,33);
        background: linear-gradient(90deg, rgba(220,226,33,1) 0%, rgba(38,218,216,1) 100%);
        border-top: none;
        padding: 10px 15px;
    }

    .FooterSignUpLabel, .FooterSignUpForm {
        display: none;
    }

    .FooterTextContainer {
        text-transform: uppercase;
    }

    .FooterTextEmail,
    .FooterTextInstagram {
        display: inline-block;
    }
}