.Stories {
    flex: 1 1;
    margin: 0 10px 40px;
    display: flex;
    justify-content: center;
    align-content: flex-start;
    flex-wrap: wrap;
}

@media only screen and (min-width: 768px) {
    .Contact {
        margin: 0 15px 60px;
    }
}