html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
}

button, a {
  cursor: pointer;
}

button, input, textarea {
  outline: none;
  border: none;
}

h1, h2, h3, h4, h5, h6, p, input, textarea, label {
  font-weight: normal;
  display: inline-block;
}

body {
  font-family: "Favorit Regular", sans-serif;
}

@font-face {
  font-family: "Favorit Regular";
  src: url("./assets/fonts/Favorit-Regular.woff") format("woff");
}

@font-face {
  font-family: "Spectral Light";
  src: url("./assets/fonts/Spectral-Light.ttf") format("truetype");
}

span.line-break {
  display: block;
}

@media only screen and (min-width: 540px) {
  span.line-break {
    display: inline;
  }
}

h1 {
  font-size: 40px;
  line-height: 1;
}

h2 {
  font-size: 23px;
  line-height: 1.1;
  letter-spacing: 1px;
}

h3 {
  font-size: 20px;
  line-height: 1.1;
}

h4 {
  font-size: 12px;
}

p, label, input, textarea {
  font-size: 18px;
  letter-spacing: -0.5px;
  line-height: 22px;
  font-family: "Spectral Light", serif;
}

@media only screen and (min-width: 480px) {
  h1 {
    font-size: 60px;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 22px;
  }

  p, label, input, textarea {
    font-size: 20px;
    line-height: 25px;
  }
}

@media only screen and (min-width: 768px) {
  h1, h2 {
    font-size: 85px;
    letter-spacing: -3px;
    line-height: 0.9;
  }

  h3 {
    font-size: 60px;
    line-height: 1;
  }

  h4 {
    font-size: 16px;
    letter-spacing: -0.37px;
  }

  p, label, input, textarea {
    font-size: 19px;
    letter-spacing: -0.2px;
  }
}

@media only screen and (min-width: 1000px) {
  h1, h2 {
    font-size: 100px;
  }

  h3 {
    font-size: 70px;
  }
}