.SecondaryNav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    position: absolute;
    top: 100%;
    left: 0;
    transform: translateY(-100%);
    transition: transform 200ms ease-in;
    background: white;
    z-index: -1;
}

.SecondaryNav.SecondaryNav--show {
    transform: translateY(0%);
}

.SecondaryNavLinkContainer {
    display: flex;
    flex-direction: column;
}

.SecondaryNavLink {
    white-space: nowrap;
    text-transform: uppercase;
    color: black;
    text-decoration: none;
    box-sizing: border-box;
    padding: 5px 0;
}

.SecondaryNavLink:first-child {
    display: none;
}

.SecondaryNavLinkIcon {
    padding-right: 10px;
    height: 30px;
    width: 30px;
}

.SecondaryNavLinkIcon g {
    stroke-width: 2.5;
}

.SecondaryNavCloseBtn {
    top: 0;
    right: 10px;
    padding-top: 25px;
    position: absolute;
    color: white;
    display: none;
    background: transparent;
    border: none;
}

.SecondaryNavCloseBtn:focus {
    outline: none;
}

.SecondaryNavCloseIcon {
    width: 35px;
    height: 35px;
}

.SecondaryNavCloseBtn g {
    stroke: white;
}

@media only screen and (min-width: 480px) {
    .SecondaryNavLinkIcon {
        height: 40px;
        width: 40px;
    }

    .SecondaryNavCloseIcon {
        width: 46px;
        height: 46px;
    }
}

@media only screen and (min-width: 768px) {
    .SecondaryNav {
        padding: 15px 15px 10px;
        top: 0;
        left: 0;
        background: black;
        z-index: 999;
    }

    .SecondaryNavLink {
        color: white;
        padding: 5px 0;
    }

    .SecondaryNavLink:hover {
        color: #17D9EB;
    }

    .SecondaryNavLink:first-child {
        display: block;
    }

    .SecondaryNavLinkContainer {
        flex-direction: column;
    }

    .SecondaryNavLinkIcon {
        display: none;
    }

    .SecondaryNavCloseBtn {
        right: 15px;
        display: block;
    }

    .SecondaryNavCloseIcon {
        height: 58px;
        width: 58px;
    }
}

@media only screen and (min-width: 1000px) {
    .SecondaryNavCloseIcon {
        height: 70px;
        width: 70px;
    }
}