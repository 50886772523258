.Home {
    flex: 1;
    padding: 0 10px 10px;
}

.HomeLink {
    color: black;
    text-decoration: none;
}

.HomeLinkHeader {
    display: flex;
    align-items: flex-start;
}

.HomeLinkHeaderTextContainer {

}

.HomeLinkHeaderText {
    text-transform: uppercase;
    flex: 1 1 100%;
    padding: 10px 0;
}

.HomeLinkSubheader {
    font-family: "Spectral Light", serif;
    font-weight: normal;
    margin: 0 0 25px;
}

.HomeLinkHeaderCircle {
    display: none;
}

.Home .Home__StoryPlaceHolderLink {
    flex: none;
    padding: 0;
    margin-bottom: 25px;
}

@media only screen and (min-width: 768px) {
    .Home {
        padding: 0 15px 10px;
    }

    .HomeLinkHeaderText {
        padding: 0;
        color: transparent;
        -webkit-text-stroke-color: black;
        -webkit-text-stroke-width: 1px;
    }

    .HomeLinkHeaderText.HomeLinkHeaderText--Rewriting {
        max-width: 1400px;
    }

    .HomeLinkSubheader {
        display: none;
    }

    .HomeLinkHeaderCircle {
        flex: 0 0 65px;
        height: 65px;
        padding: 0 10px 0 0;
        display: inline-block;
    }

    .HomeLinkHeader:hover .HomeLinkHeaderText {
        color: white;
        -webkit-text-stroke-color: unset;
        -webkit-text-stroke-width: 0;
    }

    .HomeLinkHeader:hover .HomeLinkHeaderCircle circle {
        fill: white;
        stroke: white;
    }

    .Home .Home__StoryPlaceHolderLink {
        display: none;
    }
}

@media only screen and (min-width: 1000px) {
    .HomeLinkHeaderCircle {
        flex: 0 0 74px;
        height: 74px;
        padding: 3px 14px 0 0;
        display: inline-block;
    }
}