.ImgWrapper {
    width: 100%;
    margin: 4px 0;
    box-sizing: border-box;
}

.BlogPost__ImgWrapper--first {
    order: -2;
}

.ImgWrapper.BlogPost__ImgWrapper--right-half,
.ImgWrapper.BlogPost__ImgWrapper--left-half,
.ImgWrapper.BlogPost__ImgWrapper--half {
     margin: 0;
}

.Img {
    width: 100%;
    height: auto;
}

@media only screen and (min-width: 768px) {
    .HomeLinkImgWrapper {
        display: none;
    }

    .ImgWrapper {
        margin: 12px 0;
    }

    .BlogPost__ImgWrapper--first {
        order: -1;
        margin-bottom: 29px;
    }

    .BlogPost__ImgWrapper--right {
        padding-left: 45%;
    }

    .ImgWrapper.BlogPost__ImgWrapper--right-half {
        padding-left: 52.5%;
    }

    .ImgWrapper.BlogPost__ImgWrapper--left-half {
        padding-right: 52.5%;
    }

    .ImgWrapper.BlogPost__ImgWrapper--half {
        flex: 0 0 47.5%;
        width: 47.5%;
    }
}