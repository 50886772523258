.About {
	flex: 1 1;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin: 0 10px 40px;
}

.About .About__Flex-container--first,
.About .About__Flex-container--second {
	padding: 15px 0 5px;
	box-sizing: border-box;
}

.About .About__Flex-container--first h3 {
	font-size: 30px;
}

.About p {
	margin: 8px 0;
	font-size: 20px;
}

@media only screen and (min-width: 480px) {
	.About .About__Flex-container--first h3 {
		font-size: 40px;
	}

	.About p {
		font-size: 22px;
	}
}

@media only screen and (min-width: 768px) {
	.About {
		margin: 20px 15px 60px;
		flex-wrap: nowrap;
	}

	.About .About__Flex-container--first {
		flex: 0 0 60%;
		padding: 0 5% 0 0;
	}

	.About .About__Flex-container--first h3 {
		font-size: 60px;
	}

	.About .About__Flex-container--second {
		flex: 0 0 40%;
		padding: 0 5% 0 0;
	}

	.About p {
		font-size: 18px;
	}
}

@media only screen and (min-width: 1000px) {
	.About {
		margin: 30px 15px 60px;
	}

	.About .About__Flex-container--first h3 {
		font-size: 70px;
	}
}