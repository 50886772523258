.BtnToTop {
    position: fixed;
    bottom: 150px;
    right: 10px;
    background: transparent;
    visibility: hidden;
    opacity: 0;
    padding: 0;
    transition: all 300ms ease-in;
    z-index: 900;
}

.BtnToTopIcon {
    height: 35px;
    width: 35px;
}

.BtnToTop.BtnToTop--show {
    opacity: 1;
    visibility: visible;
}

@media only screen and (min-width: 768px) {
    .BtnToTop {
        right: 15px;
    }

    .BtnToTopIcon {
        height: 70px;
        width: 70px;
    }
}