.Content {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-sizing: border-box;
}

.Observations {
    background-image: url('../../assets/images/Observations_bg.jpg');
}

.RunRiver {
    background-image: url('../../assets/images/RunRiver/RRN_1.jpg');
}

.Learning {
    background-image: url('../../assets/images/Learning_bg.jpg');
}

.MaxB {
    background-image: url('../../assets/images/MaxB/MAXB_11.jpg');
}