.BlogPost {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    margin: 0 10px 40px;
}

.BlogPost p {
    margin: 12px 0;
}

.BlogPost .BlogPost__Pull-Quotes {
    margin: 12px 0;
    font-size: 30px;
}

.BlogPost .BlogPost__Title {
    text-transform: uppercase;
    margin: 8px 0 12px;
    order: -1;
}

.BlogPost .BlogPost__Title--Rewriting {
    max-width: 1400px;
}

.BlogPost .BlogPost__ImgWrapperFlexContainer {
    display: block;
    justify-content: space-between;
}

@media only screen and (min-width: 480px) {
    .BlogPost .BlogPost__Pull-Quotes {
        font-size: 40px;
    }
}

@media only screen and (min-width: 768px) {
    .BlogPost {
        margin: 0 15px 60px;
    }

    .BlogPost p {
        margin-left: 45%;
    }

    .BlogPost .BlogPost__Pull-Quotes {
        font-size: 60px;
        margin: 15px 15% 15px 15%;
    }

    .BlogPost .BlogPost__Title {
        order: -2;
        color: transparent;
        -webkit-text-stroke-color: black;
        -webkit-text-stroke-width: 1px;
    }

    .BlogPost .BlogPost__ImgWrapperFlexContainer {
        margin: 20px 0;
    }

    .BlogPost .BlogPost__ImgWrapperFlexContainer {
        display: flex;
    }
}

@media only screen and (min-width: 1000px) {
    .BlogPost .BlogPost__Pull-Quotes {
        font-size: 70px;
    }
}