.Contact {
    flex: 1 1;
    margin: 0 10px 40px;
}

.ContactHeader,
.ContactContent {
    box-sizing: border-box;
    padding: 15px 0 5px;
}

.ContactHeader h3 {
    font-size: 30px;
    display: inline-block;
}

.ContactHeader h3.ContactHeaderLight {
    color: #c8c8c8;
}

.ContactContent p {
    font-size: 20px;
    margin: 8px 0;
}

.ContactForm__Inputs {
    display: block;
    outline: none;
    border: solid 1px black;
    width: 100%;
    margin: 15px 0;
    padding: 8px 12px 5px;
    box-sizing: border-box;
}

.ContactForm__Textarea {
    min-height: 200px;
    min-width: 100%;
}

.ContactForm__Inputs::-webkit-input-placeholder { /* Edge */
    color: #cbcbcb;
}

.ContactForm__Inputs:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #cbcbcb;
}

.ContactForm__Inputs::placeholder {
    color: #cbcbcb;
    opacity: 1;
}

.ContactForm__Submit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    outline: none;
    border: none;
    background: black;
    color: white;
    width: 100%;
    margin: 15px 0;
    padding: 13px 12px 8px;
    box-sizing: border-box;
}

.ContactForm__SubmitIcon {
    padding-bottom: 5px;
    height: 15px;
    width: 15px;
}

.ContactForm__SubmitIcon g {
    stroke: white;
    stroke-width: 3;
}

.ContactContent .ContactForm__Submit p {
    padding: 0;
    font-family: "Favorit Regular", sans-serif;
}

@media only screen and (min-width: 480px) {
    .ContactHeader h3 {
        font-size: 40px;
    }

    .ContactContent p {
        font-size: 22px;
    }
}

@media only screen and (min-width: 768px) {
    .Contact {
        margin: 20px 15px 60px;
        display: flex;
    }

    .ContactHeader {
        flex: 0 0 60%;
        padding: 0 5% 0 0;
    }

    .ContactContent {
        flex: 0 0 40%;
        padding: 0 5% 0 0;
    }

    .ContactHeader h3 {
        font-size: 60px;
    }

    .ContactContent p {
        font-size: 18px;
    }
}

@media only screen and (min-width: 1000px) {
    .Contact {
        margin: 30px 15px 60px;
    }

    .ContactHeader h3 {
        font-size: 70px;
    }
}