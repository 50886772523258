.StoryLinkWrapper,
.StoryPlaceHolder {
    flex: 1 1 100%;
    position: relative;
    padding: 5%;
    box-sizing: border-box;
}

.StoryPlaceHolder {
    align-self: flex-start;
}

.StoryLink {
    color: black;
    width: 100%;
    height: 100%;
}

.StoryLinkHeader {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1.25;
}

.StoryPlaceHolderHeader {
    text-transform: uppercase;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.StoryLinkDescription {
    font-size: 13px;
    line-height: 1.25;
}

@media only screen and (min-width: 768px) {
    .StoryLinkWrapper,
    .StoryPlaceHolder {
        flex: 0 0 33.3%;
        width: 33.3%;
    }

    .StoryLinkHeader {
        font-size: 20px;
        padding: 0 10% 5px 0;
    }

    .StoryPlaceHolderHeader {
        font-size: 20px;
    }

    .StoryLinkDescription {
        font-size: 16px;
        padding: 0 10% 0 0;
    }
}