.PrimaryNav {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    top: 0;
    z-index: 999;
}

.PrimaryNavContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    padding: 10px 20px 0 10px;
}

.PrimaryNavLink {
    text-transform: uppercase;
    color: black;
    text-decoration: none;
}

.PrimaryNavBtn {
    display: block;
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    background: transparent;
}

.PrimaryNavDotdotdot {
    height: 12px;
    width: 48px;
}

.PrimaryNavX {
    width: 30px;
    height: 30px;
}

@media only screen and (min-width: 480px) {
    .PrimaryNavDotdotdot {
        height: 16px;
        width: 64px;
    }

    .PrimaryNavX {
        width: 40px;
        height: 40px;
    }
}

@media only screen and (min-width: 768px) {
    .PrimaryNav {
        z-index: 998;
    }

    .PrimaryNavContent {
        padding: 15px 30px 0 15px;
        background: transparent;
    }

    .PrimaryNavDotdotdot {
        height: 20px;
        width: 80px;
    }

    .PrimaryNavX {
        height: 58px;
        width: 58px;
    }
}

@media only screen and (min-width: 1000px) {
    .PrimaryNavDotdotdot {
        height: 24px;
        width: 96px;
    }

    .PrimaryNavX {
        height: 70px;
        width: 70px;
    }
}